<template>
  <div :class="['system-layout', 'app-container', isIpad && 'app-ipad']">
    <div class="app-main">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
// import Header from './components/Header'
import { judgeIsIpad } from '@/utils/index'
export default {
  components: {},
  data() {
    return {
      isIpad: false // ipad 需固定网页 防止滑动（避免图谱滑动冲突）0
    }
  },
  computed: {
  },
  mounted() {
    // this.preventPageBack()
  },
  methods: {
    //阻止用户返回
    preventPageBack() {
      const state = { page: 1 };
      const title = '';
      const url = '#';

      history.pushState(state, title, url);
    }
  },
  watch: {
    '$route': {
      handler: function (to, from) {
        // 路由发生变化时的处理逻辑
        console.log(to)
        if (to.name != 'Home') {
          history.go(0)
        }
        // history.go(0)
      },
      // 设置为深度监听
      deep: true
    }
  },
}
</script>

<style scoped lang="less">
@font-face {
  font-family: 'pingfang';
  src: url('./components/Header/img/text.ttf');
}

.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  color: #333;
  background-color: #F0F5F6;
  overflow-x: hidden;
  font-family: 'pingfang', 'Source Han Sans CN', Avenir, Helvetica, Arial, sans-serif !important;

  &.app-ipad {
    position: fixed;
    top: 0;
    left: 0;
  }

  .navbar {
    position: absolute;
    left: 0;
    top: 0;
    height: 163px;
    width: 100%;
    background: transparent;
    z-index: 1;
  }

  .app-main {
    position: absolute;
    top: 0px;
    width: 100%;
    height: calc(100% - 0px);
    z-index: 2;

    ::v-deep .page-wrap {
      width: 100%;
      height: 100%;

      .content-box {
        position: relative;
        padding-left: 10px;
      }
    }
  }

}

.app-container::-webkit-scrollbar {
  height: 2px;
  display: none;
}






/deep/ .el-button--mini {
  border-radius: 5px;
}
</style>
